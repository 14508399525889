export const GET_TIMETABLE_DAYS = "GET_TIMETABLE_DAYS"
export const GET_TIMETABLE_DAYS_SUCCESS = "GET_TIMETABLE_DAYS_SUCCESS"
export const GET_TIMETABLE_DAYS_FAILED = "GET_TIMETABLE_DAYS_FAILED"

export const GET_TIMETABLE = "GET_TIMETABLE"
export const GET_TIMETABLE_SUCCESS = "GET_TIMETABLE_SUCCESS"
export const GET_TIMETABLE_FAILED = "GET_TIMETABLE_FAILED"

export const TIMETABLE_HIDE_COL = "TIMETABLE_HIDE_COL"
export const TIMETABLE_HIDE_COL_SUCCESS = "TIMETABLE_HIDE_COL_SUCCESS"
export const TIMETABLE_HIDE_COL_FAILED = "TIMETABLE_HIDE_COL_FAILED"