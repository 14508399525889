import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, GET_PROFILE_DETAIL_FAILED, GET_PROFILE_DETAIL, GET_PROFILE_DETAIL_SUCCESS } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  Loading: false,
  profileDetails: {}
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
    case GET_PROFILE_DETAIL:
      state = {
        ...state,
        Loading: true
      };
      break;
    case GET_PROFILE_DETAIL_SUCCESS:
      state = {
        ...state,
        Loading: false,
        profileDetails: action.payload
      };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        Loading: false
      };
      break;
    case GET_PROFILE_DETAIL_FAILED:
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
