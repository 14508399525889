import { GET_ALBUM, GET_ALBUM_FAILED, GET_ALBUM_IMAGES, GET_ALBUM_IMAGES_FAILED, GET_ALBUM_IMAGES_SUCCESS, GET_ALBUM_SUCCESS, UPLOAD_GALLERY_IMAGE, UPLOAD_GALLERY_IMAGE_FAILED, UPLOAD_GALLERY_IMAGE_SUCCESS } from "./constants";

const initialState = {
    Loading: false,
    successMsg: "",
    errors: "",
    albumList: [],
    imageList: []
}

const GallaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALBUM:
        case GET_ALBUM_IMAGES:
        case UPLOAD_GALLERY_IMAGE:
            return {
                ...state,
                Loading: true,
            }
        case GET_ALBUM_SUCCESS:
            return {
                ...state,
                Loading: false,
                albumList: action.payload
            }
        case GET_ALBUM_IMAGES_SUCCESS:
            return {
                ...state,
                Loading: false,
                imageList: action.payload
            }
        case UPLOAD_GALLERY_IMAGE_SUCCESS:
            return{
                ...state,
                Loading: false,
                successMsg: action.payload
            }
        case GET_ALBUM_FAILED:
        case GET_ALBUM_IMAGES_FAILED:
        case UPLOAD_GALLERY_IMAGE_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload
            }

        default:
            return state
    }
}

export default GallaryReducer;