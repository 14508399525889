import React, { useEffect, useMemo, useState } from 'react'
import classnames from "classnames";
import "./ViewTimeTable.css"
import { Breadcrumb, Card, CardBody, Col, Input, Row, Spinner, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getCurrentAcadid, getTimeTable, getTimeTableDays, timeTableHideCol } from 'store/actions';
import { useSelector } from 'react-redux';
import Spinners from 'components/Common/Spinner';
import moment from 'moment';

const ViewTimeTable = () => {

    const dispatch = useDispatch();

    const { Loading, errors, successMsg, timeTableDays, timeTable, HideCol } = useSelector((state) => state.TimeTableReducer);
    const { acadID } = useSelector(state => state.CommonReducer);
    const [selectedDay, setSelectedDay] = useState("");
    const [showDay, setShowDay] = useState("");

    const date = new Date();
    const currectDayCount = date.getDay()

    const handleGetTimeTable = (day) => {
        dispatch(getTimeTable({ day: day.id, acadid: acadID?.acadid ? acadID.acadid: "" }))
        setSelectedDay(day.id)
        setShowDay(day.day)
    }

    const daysElements = useMemo(() => (
        timeTableDays && Array.isArray(timeTableDays) ? timeTableDays.map((data, index) => (
            <div
                key={index}
                className={classnames('days', { selectedDay: selectedDay === data.id })}
                onClick={() => handleGetTimeTable(data)}
            >
                {data.day.slice(0, 3)}
            </div>
        )) : null
    ), [timeTableDays, selectedDay, handleGetTimeTable]);


    const timetableElements = useMemo(() => (
        timeTable && Array.isArray(timeTable) && timeTable.length > 0 ? (
            timeTable.map((data, index) => (
                <Row className='' key={index}>
                    <Col className='col-2 p-0'>
                        <div className='leftNumberContainer'>
                            <span className='leftNumber'>{index + 1}</span>
                        </div>
                    </Col>
                    <Col className='col-10 border border-bottom py-2 ps-1'>
                        <div className='rightContainer'>
                            <div className='lectureData'>

                                <p className='m-0 time'>{data.lecture}</p>

                                {
                                    HideCol && HideCol.subjecthide !== "1" && <p className='m-0 lecture'>{data.subject}</p>
                                }
                                {
                                    HideCol && HideCol.teacherhide !== "1" && <p className='m-0 teacherName'>{data.teachersName}</p>
                                }
                            </div>
                            {
                                data.meeting_ID && data.meeting_ID !== "" &&

                                <div className='meetingID'>
                                    <a href={data.meeting_ID} target='_blank' rel='noreferrer'>
                                        <i className='fas fa-video' />
                                    </a>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            ))
        ) : (
            <p className='text-center'>No timetable available for the selected day.</p>
        )
    ), [timeTable]);


    useEffect(() => {
        if (Array.isArray(timeTableDays) && timeTableDays.length > 0) {

            handleGetTimeTable({ id: currectDayCount, day: moment(date).format('dddd') })
            // dispatch(getTimeTable({ day: currectDayCount }))
            // setSelectedDay(currectDayCount)
            // setShowDay(moment(date).format('dddd'))
        }
    }, [timeTableDays])

    useEffect(() => {
        dispatch(getTimeTableDays())
        dispatch(timeTableHideCol())
    }, [dispatch])

    useEffect(() => {
        if (localStorage.getItem("sid")) {
          dispatch(getCurrentAcadid({
            sid: localStorage.getItem("sid")
          }))
        }else{
          toast.error("SID missing")
        }
    
      }, [])

    


    return (
        <div className='page-content px-0 bg-white' style={{paddingTop: "62px"}}>
            {/* <Breadcrumb title="Tables" breadcrumbItem="Time Tables" /> */}
            <div className='timeTableContainer'>
                <div className='dayTableContainer position-fixed w-100'>
                    {daysElements}
                </div>
                <div className='pt-5'>
                    <hr className='mt-2' />
                    <p className='text-primary m-2 fs-5'>{showDay}</p>
                    <hr className='mt-2 mb-0' />
                </div>
                {
                    Loading ?
                        <div className='w-100 d-flex flex-column align-items-center justify-content-center' style={{height:"65vh"}}>
                            <Spinner className='mt-4 text-primary' />
                        </div> :

                        <div className='subContainer'>
                            {timetableElements}
                        </div>
                }
            </div>

        </div>
    )
}

export default ViewTimeTable