import React from "react"

const ResuableComponent = ({ div, subject, date, status }) => {
  const statusClass =
    status === "PENDING"
      ? "text-warning"
      : status === "APPROVED"
      ? "text-success"
      : ""
  return (
    <div
      style={{
        backgroundColor: "#fff",
        // border: "1px solid #000",
        borderRadius: "4px",
        marginTop: "5px",
        padding: "10px",
        position: "relative",
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <span style={{ fontWeight: 600 }} className="mt-3 w-50">
          Class:
        </span>
        <span className="mt-3 w-50 text-md-end">{div}</span>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <span style={{ fontWeight: 600 }} className="mt-3 w-50">
          Subject:
        </span>
        <span className="mt-3 w-50 text-md-end">{subject}</span>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <span style={{ fontWeight: 600 }} className="mt-3 w-50">
          Date:
        </span>
        <span className="mt-3 w-50 text-md-end">{date}</span>
      </div>

      <div className="d-flex flex-row justify-content-between">
        <span style={{ fontWeight: 600 }} className="mt-3 w-50">
          Status:
        </span>
        <span className={`mt-3 w-50 text-md-end ${statusClass}`}>{status}</span>{" "}
      </div>
    </div>
  )
}
const Report = () => {
  return (
    <>
      <div className="page-content">
        <ResuableComponent
          div={"7-A"}
          subject={"C.A, Diction, Handwriting, Reading"}
          date={"19-07-2024"}
          status={"PENDING"}
        />
        <ResuableComponent
          div={"7-A"}
          subject={"C.A, Diction, Handwriting, Reading"}
          date={"19-07-2024"}
          status={"PENDING"}
        />
        <ResuableComponent
          div={"2-A"}
          subject={"C.A, Diction, Handwriting, Reading"}
          date={"29-07-2024"}
          status={"APPROVED"}
        />
        <ResuableComponent
          div={"7-A"}
          subject={"C.A, Diction, Handwriting, Reading"}
          date={"19-07-2024"}
          status={"APPROVED"}
        />
      </div>
    </>
  )
}

export default Report
