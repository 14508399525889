import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const createFeedbackAPI = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/feedback/create-feedback`, payload, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error While Creating Feedback:", error)
        throw error
    }
}

export const getFeedbackListAPI = async () => {
    try {
        const apiToken = JSON.parse(localStorage.getItem("Token"));
        const groupId = localStorage.getItem("group_id");

        if (!apiToken || !groupId) {
            throw new Error("Token or Group ID is missing");
        }

        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        };

        let response;
        if (groupId == 2) {
            response = await axios.get(`${liveApiUrl}/api/feedback/get-convesations`, { headers });
        } else if (groupId == 9) {
            response = await axios.post(`${liveApiUrl}/api/feedback/parent-feedbacks`, {}, { headers });
        } else {
            throw new Error("Group ID is not valid");
        }

        return response;
    } catch (error) {
        console.error("Error GETTING FEEDBACK LIST:", error);
        throw error;
    }
};



export const openFeedbackChatsAPI = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/feedback/open-feedback`, payload, {
            headers,
        })
        return response
    } catch (error) {
        console.error(error)
        throw error
    }
}


export const sendFeebackMsgAPI = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/feedback/create-feedback-thread`, payload, {
            headers,
        })
        return response
    } catch (error) {
        console.error(error)
        throw error
    }
}


export const getStaffListAPI = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": apiToken,
            "Accept": "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/feedback/assigned-teacher-details`, {
            headers,
        })
        return response
    } catch (error) {
        console.error(error)
        throw error
    }
}
