import { GET_SUBJECT_START, SUBMIT_REQUEST_START } from "./constant"

import {
  getSubjectListSuccess,
  getSubjectListFailed,
  SubmitAccessRequestSuccess,
  SubmitAccessRequestFailed,
} from "./action"
import { call, put, takeLatest, delay } from "redux-saga/effects"

import { getSubjectListApi, submitRequestAccessApi } from "./Api"

function* getSubjectList(action) {
  console.log(action)
  try {
    const response = yield call(getSubjectListApi, action.payload)
    if (response) {
      delay(400)
      yield put(getSubjectListSuccess(response.data))
    } else {
      throw response
    }
  } catch (error) {
    yield put(getSubjectListFailed(error.response.data.message))
  }
}

// GET SUBJECT ACCESS REQUEST

function* subjectAccessReqfunc(action) {
  try {
    const response = yield call(submitRequestAccessApi, action.payload)
    if (response) {
      delay(400)
      yield put(SubmitAccessRequestSuccess(response.data))
    } else {
      throw response
    }
  } catch (error) {
    yield put(SubmitAccessRequestFailed(error.response.data.message))
  }
}
function* SubjectRequestSaga() {
  yield takeLatest(GET_SUBJECT_START, getSubjectList)
  yield takeLatest(SUBMIT_REQUEST_START, subjectAccessReqfunc)
}

export default SubjectRequestSaga
