import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE_DETAIL } from "./actionTypes"
import { profileSuccess, profileError, getProfileDetailSuccess, getProfileDetailFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper"
import { getProfileDetailsAPI } from "./api"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      )
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}


function* getProfileDetailsAsync() {
  try {
    const response = yield call(getProfileDetailsAPI);
    if(response && Array.isArray(response?.data)){
      yield put(getProfileDetailSuccess(response?.data[0]));
    }else{
      throw new Error("Error occured while getting profile")
    }
  } catch (error) {
    console.error('Error fetching profile details:', error);
    yield put(getProfileDetailFailed(error?.response?.data?.message|| "Unable to get profile details"));
  }
}
export function* watchgetProfileDetails() {
  yield takeEvery(GET_PROFILE_DETAIL, getProfileDetailsAsync)
}

function* ProfileSaga() {
  yield all([fork(watchProfile), fork(watchgetProfileDetails)])
}

export default ProfileSaga
