import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getProfileDetailsAPI = async() => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))       
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/parent-auth/profile`, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}
///api/dashboard/get-school-url

