import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, messageCard, Row } from 'reactstrap'
import "./FeedbackStyle.css"
import { useDispatch } from 'react-redux'
import { openFeedbackchats, sendFeedbackMsg } from 'store/actions'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
const ChatWindow = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const lastMessageRef = useRef(null);
    const [newMessage, setNewMessage] = useState("")
    const { conversationID, chatSubject } = location.state

    const { messageList, errors, sendMessageStatus } = useSelector(state => state.FeedBackReducer)

    const dates = new Set();

    const renderDate = (dateNum) => {
        dates.add(dateNum);

        return <div className='my-2 text-center' style={{ fontSize: "10px" }}>
            {/* <Button className='btn-sm disabled' color='white'><b className='text-dark'></b></Button> */}
            <div className='p-2 mb-1 bg-white d-inline-block border rounded-4 shadow '>
                {dateNum}
            </div>
        </div>
    };


    const [isKeyboardVisible, setKeyboardVisible] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        const windowHeight = window.innerHeight;
        const initialHeight = window.screen.height;
  
        // If the difference is significant, assume the keyboard is visible
        const keyboardThreshold = 100; // Adjust as needed
        setKeyboardVisible(initialHeight - windowHeight > keyboardThreshold);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    useEffect(() => {
        if (errors && errors !== null) {
            toast.error(errors)
        }
        if (sendMessageStatus === 1) {
            setNewMessage("")

        }
        console.log(sendMessageStatus)
    }, [errors, sendMessageStatus])

    const handleSendMessage = () => {
        if (newMessage && newMessage !== "") {
            dispatch(sendFeedbackMsg(
                {
                    conversation_id: conversationID,
                    message: newMessage,
                    //  "device_name":"Device name (optional)"
                }))
        }
    }

    useEffect(() => {
        dispatch(openFeedbackchats({
            conversation_id: conversationID
        }))
    }, [conversationID])

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ block: 'end' });
        }
    }, [messageList, isKeyboardVisible]);
    return (
        <div className={`page-content ${!isKeyboardVisible ? 'chatMain': 'fullwidth'} `}>

            <div className="messageCard">
                <header className="messageCard-header">
                    <p className="messageCard-header-title">
                        <i className="fas fa-circle is-online"></i>{chatSubject ? chatSubject : ""}
                    </p>
                </header>

                <div className="messageCard-content chat-content">

                    {

                        messageList && messageList.length > 0 && messageList.map((msg, index) => {
                            const dateNum = moment(msg.createdDate).format('DD/MM/YYYY');
                            return (
                                <>
                                    {dates.has(dateNum) ? null : renderDate(dateNum)}
                                    <div className={`chat-message-group ${msg.direction === 'out' ? 'writer-user' : null}`} key={index}>
                                        {/* <div className="chat-thumb">
                                                        <figure className="image is-32x32">
                                                            <img src="https://k0.okccdn.com/php/load_okc_image.php/images/32x0/971x939/0/10846088441021659030.webp?v=2" />
                                                        </figure>
                                                    </div> */}
                                        <div className="chat-messages">
                                            <div className="message">{msg.message}</div>
                                            <div className="from">{moment(msg.createdDate).format('hh:mm A')}</div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }


                    {/* <div className="chat-message-group writer-user">
                            <div className="chat-messages">
                                <div className="message">asdasd asds asd</div>
                                <div className="from">04:55</div>
                            </div>
                        </div> */}
                    <div className="chat-message-group">
                        <div className="chat-messages" style={{ height: "10px" }} ref={lastMessageRef} />
                    </div>
                </div>

                <div className="messageCard-footer w-100" id="chatBox-textbox">


                    <textarea
                        id="chatTextarea"
                        className="chat-textarea fs-5"
                        placeholder="Type Message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    >
                    </textarea>

                    <div className='sendButton d-flex align-items-center justify-content-right'>
                        {newMessage !== "" ? <i className='bx bx-send btn btn-primary btn-rounded ms-1' onClick={handleSendMessage}></i> : null}

                    </div>
                </div>

            </div>

        </div>
    )
}

export default ChatWindow