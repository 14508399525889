import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { CREATE_FEEDBACK, GET_DASH_ICONS, GET_FEEDBACK_LIST, GET_STAFF_LIST, OPEN_FEEDBACK_CHAT, SEND_FEEDBACK_MESSAGE } from "./constants";
import { createFeedbackAPI, getFeedbackListAPI, getStaffListAPI, openFeedbackChatsAPI, sendFeebackMsgAPI } from "./api";
import { createFeedbackFailed, createFeedbackSuccess, getFeedbackListFailed, getFeedbackListSuccess, getStaffListFailed, getStaffListSuccess, openFeedbackchatsFailed, openFeedbackchatsSuccess, sendFeedbackMsgFailed, sendFeedbackMsgSuccess } from "./actions";

function* createFeedbackAsync(action) {
    try {
        const response = yield call(createFeedbackAPI, action.payload)
        if (response) {
            yield put(createFeedbackSuccess(response?.data?.message || "Created Successfully"))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(createFeedbackFailed(error.response.data.message || "Unable to Create feedbacks"))
    }
}

function* getFeedbackListAsync() {
    try {
        const response = yield call(getFeedbackListAPI);
        if (response) {
            yield put(getFeedbackListSuccess(response?.data || []));
        } else {
            throw new Error("No response received");
        }
    } catch (error) {
        console.error("Error GETTING FEEDBACK LIST:", error);
        yield put(getFeedbackListFailed(error.response?.message || "Unable to get feedbacks"));
    }
}


function* sendFeedbackMsgAsync(action) {
    try {
        const response = yield call(sendFeebackMsgAPI, action.payload)
        if (response) {
            yield put(sendFeedbackMsgSuccess(action.payload.message))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(sendFeedbackMsgFailed(error.response.data.message || "Unable to send feedbacks message"))
    }
}

function* openFeedbackChatsAsync(action) {
    try {
        const response = yield call(openFeedbackChatsAPI, action.payload)
        if (response) {
            yield put(openFeedbackchatsSuccess(response?.data || []))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(openFeedbackchatsFailed("Unable to open feedbacks message"))
    }
}


function* getStaffListAsync(action) {
    try {
        const response = yield call(getStaffListAPI, action.payload)
        if (response) {
            yield put(getStaffListSuccess(response.data?.records || []))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getStaffListFailed(error.response.message || "Unable to open feedbacks message"))
    }
}

function* FeedbackSaga() {

    yield takeEvery(GET_FEEDBACK_LIST, getFeedbackListAsync),
    yield takeEvery(CREATE_FEEDBACK, createFeedbackAsync),
    yield takeEvery(SEND_FEEDBACK_MESSAGE, sendFeedbackMsgAsync),
    yield takeEvery(OPEN_FEEDBACK_CHAT, openFeedbackChatsAsync),
    yield takeEvery(GET_STAFF_LIST, getStaffListAsync)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default FeedbackSaga;