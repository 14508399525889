import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { getTimeTableApi, getTimeTableDaysApi, timeTableHideColApi } from "./api";
import { getTimeTableDaysFailed, getTimeTableDaysSuccess, getTimeTableFailed, getTimeTableSuccess, timeTableHideColFailed, timeTableHideColSuccess } from "./actions";
import { GET_TIMETABLE, GET_TIMETABLE_DAYS, TIMETABLE_HIDE_COL } from "./Constants";


function* getTimeTableDaysAsync(action) {
    try {
        const response = yield call(getTimeTableDaysApi)
        if (response) {
            yield put(getTimeTableDaysSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getTimeTableDaysFailed(error.response.data || "Unable to get Days, please try again"))
    }
}

function* getTimeTableAsync(action) {
    try {
        const response = yield call(getTimeTableApi, action.payload)
        if (response) {
            yield put(getTimeTableSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getTimeTableFailed(error.response.data || "Unable to get time table, please try again"))
    }
}


function* timeTableHideColAsync() {
    try {
        const response = yield call(timeTableHideColApi)
        if (response) {
            yield put(timeTableHideColSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(timeTableHideColFailed(error.response.data || "Unable to get time table, please try again"))
    }
}

function* TimeTableSaga() {

    yield takeEvery(GET_TIMETABLE_DAYS, getTimeTableDaysAsync)
    yield takeEvery(GET_TIMETABLE, getTimeTableAsync)
    yield takeEvery(TIMETABLE_HIDE_COL, timeTableHideColAsync)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default TimeTableSaga;