import React, { useState, useEffect } from 'react';
import { Input, Label } from 'reactstrap';

const FileUpload = ({ setUploadFiles }) => {
    const [attachments, setAttachments] = useState({
        file_attach: "",
        thumbnail: "",
        file_attach2: "",
        thumbnail2: "",
        file_attach3: "",
        thumbnail3: "",
    });

    const FileUploadstyle = {
        fileUpload: {
            display: 'none',
        },
        fileUploadLabel: {
            display: 'inline-block',
            padding: '5px 3px',
            cursor: 'pointer',
            fontSize: '15px',
        },
    };

    const handleFileUpload = (input) => {
        if (input.target.files.length < 4) {
            const fileList = input.target.files;
            const newAttachments = { ...attachments };

            for (let i = 0; i < fileList.length; i++) {
                console.log(fileList[i]);
                if (i === 0) {
                    newAttachments.file_attach = fileList[i];
                } else if (i === 1) {
                    newAttachments.file_attach2 = fileList[i];
                } else if (i === 2) {
                    newAttachments.file_attach3 = fileList[i];
                }
            }

            setAttachments(newAttachments);
        } else {
            alert('You can only upload a maximum of 3 files');
            input.value = ''; // Clear the input
        }
    };

    useEffect(() => {
        setUploadFiles(attachments);
    }, [attachments]);

    return (
        <div>

            {attachments.file_attach?.name ? <span className='d-block mb-1'>{attachments.file_attach?.name}</span> : ""}
            {attachments.file_attach2?.name ? <span className='d-block mb-1'>{attachments.file_attach2?.name}</span> : ""}
            {attachments.file_attach3?.name ? <span className='d-block mb-1'>{attachments.file_attach3?.name}</span> : ""}
            <Input
                type="file"
                id="file-upload"
                style={FileUploadstyle.fileUpload}
                onChange={(e) => handleFileUpload(e)}
                multiple={true}
            />
            <Label
                for="file-upload"
                style={FileUploadstyle.fileUploadLabel}
                title="file attach"
            >
                <i className="bx bx-paperclip"></i>
            </Label>
        </div>
    );
};

export default FileUpload;
