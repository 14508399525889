import {
    GET_SUBJECT_START,
    GET_SUBJECT_SUCCESS,
    GET_SUBJECT_FAILED,
    SUBMIT_REQUEST_START,
    SUBMIT_REQUEST_SUCCESS,
    SUBMIT_REQUEST_FAILED,
  } from "./constant";
  
  const initialState = {
    errors: "",
    message: "",
    loading: false,
    report: [],
    subjectList : [],
    submitRequest: false,
  };
  
  const SubjectRequest = (state = initialState, action) => {
    switch (action.type) {
      case GET_SUBJECT_START:
        return {
          ...state,
          loading: true,
          errors: "",
        };
  
      case GET_SUBJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          subjectList: action.payload, 
        };
  
      case GET_SUBJECT_FAILED:
        return {
          ...state,
          loading: false,
          errors: action.payload,
        };
  
      case SUBMIT_REQUEST_START:
        return {
          ...state,
          submitRequest: true,
          errors: "",
        };
  
      case SUBMIT_REQUEST_SUCCESS:
        return {
          ...state,
          submitRequest: false,
          message: action.payload,
        };
  
      case SUBMIT_REQUEST_FAILED:
        return {
          ...state,
          submitRequest: false,
          errors: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default SubjectRequest;
  