export const GET_ALBUM = "GET_ALBUM"
export const GET_ALBUM_SUCCESS = "GET_ALBUM_SUCCESS"
export const GET_ALBUM_FAILED = "GET_ALBUM_FAILED"


export const GET_ALBUM_IMAGES = "GET_ALBUM_IMAGES"
export const GET_ALBUM_IMAGES_SUCCESS = "GET_ALBUM_IMAGES_SUCCESS"
export const GET_ALBUM_IMAGES_FAILED = "GET_ALBUM_IMAGES_FAILED"

export const UPLOAD_GALLERY_IMAGE = "UPLOAD_GALLERY_IMAGE"
export const UPLOAD_GALLERY_IMAGE_SUCCESS = "UPLOAD_GALLERY_IMAGE_SUCCESS"
export const UPLOAD_GALLERY_IMAGE_FAILED = "UPLOAD_GALLERY_IMAGE_FAILED"