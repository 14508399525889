import { GET_CURRENT_ACADID, GET_CURRENT_ACADID_FAILED, GET_CURRENT_ACADID_SUCCESS } from "./Constants";

export const getCurrentAcadid = (payload) =>({
    type: GET_CURRENT_ACADID,
    payload
})

export const getCurrentAcadidSuccess = (payload) =>({
    type: GET_CURRENT_ACADID_SUCCESS,
    payload
})

export const getCurrentAcadidFailed = (payload) =>({
    type: GET_CURRENT_ACADID_FAILED,
    payload
})


