export const GET_FEEDBACK_LIST = "GET_FEEDBACK_LIST"
export const GET_FEEDBACK_LIST_SUCCESS = "GET_FEEDBACK_LIST_SUCCESS"
export const GET_FEEDBACK_LIST_FAILED = "GET_FEEDBACK_LIST_FAILED"

export const CREATE_FEEDBACK= "CREATE_FEEDBACK"
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS"
export const CREATE_FEEDBACK_FAILED= "CREATE_FEEDBACK_FAILED"

export const SEND_FEEDBACK_MESSAGE = "SEND_FEEDBACK_MESSAGE"
export const SEND_FEEDBACK_MESSAGE_SUCCESS = "SEND_FEEDBACK_MESSAGE_SUCCESS"
export const SEND_FEEDBACK_MESSAGE_FAILED= "SEND_FEEDBACK_MESSAGE_FAILED"

export const OPEN_FEEDBACK_CHAT = "OPEN_FEEDBACK_CHAT"
export const OPEN_FEEDBACK_CHAT_SUCCESS = "OPEN_FEEDBACK_CHAT_SUCCESS"
export const OPEN_FEEDBACK_CHAT_FAILED= "OPEN_FEEDBACK_CHAT_FAILED"

export const GET_STAFF_LIST = "GET_STAFF_LIST"
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS"
export const GET_STAFF_LIST_FAILED= "GET_STAFF_LIST_FAILED"

