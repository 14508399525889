import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { GET_BOTTOM_NAV, GET_DASH_ICONS } from "./constants";
import { getBottomNavApi, getDashIconsAPI } from "./api";
import { getBottomNavFailed, getBottomNavSuccess, getDashIconsFail, getDashIconsSuccess } from "./actions";

function* getDashIconsAsync() {
    try {
        const response = yield call(getDashIconsAPI)
        if (response) {
            yield put(getDashIconsSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getDashIconsFail(error.response.data || "Unable to get Icons"))
    }
}

function* getBottomNavSaga() {
    try {
        const response = yield call(getBottomNavApi)
        if (response) {
            yield put(getBottomNavSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getBottomNavFailed(error.response.data || "Unable to get Bottom Nav Icons Icons"))
    }
}

function* DashboardSaga() {

    yield takeEvery(GET_DASH_ICONS, getDashIconsAsync)
    yield takeEvery(GET_BOTTOM_NAV, getBottomNavSaga)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default DashboardSaga;