import { GET_BOTTOM_NAV, GET_BOTTOM_NAV_FAILED, GET_BOTTOM_NAV_SUCCESS, GET_DASH_ICONS, GET_DASH_ICONS_FAIL, GET_DASH_ICONS_SUCCESS } from "./constants";

const initialState = {
    Loading: false,
    errors: "",
    successMsg: "",
    dashIcons: [],
    bottomNavIcons: []
}

const DashReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASH_ICONS:
        case GET_BOTTOM_NAV:
            return {
                ...state,
                Loading: true,
                errors: "",
                successMsg: "",
            }
        case GET_DASH_ICONS_SUCCESS:
            return {
                ...state,
                Loading: false,
                dashIcons: action.payload,
            }
        case GET_BOTTOM_NAV_SUCCESS:
            return {
                ...state,
                Loading: false,
                bottomNavIcons: action.payload
            }
        case GET_DASH_ICONS_FAIL:
        case GET_BOTTOM_NAV_FAILED:
            return {
                ...state,
                Loading: false,
                errros: action.payload
            }
        default:
            return state;
    }
}

export default DashReducer;