import { GET_ATTENDANCE_DIV, GET_ATTENDANCE_DIV_FAILED, GET_ATTENDANCE_DIV_SUCCESS, GET_STUDLIST, GET_STUDLIST_FAIL, GET_STUDLIST_SUCCESS, MARK_ABSENT_STUD, MARK_ABSENT_STUD_FAILED, MARK_ABSENT_STUD_SUCCESS } from "./constants"

export const getAttendanceDiv = () =>({
    type: GET_ATTENDANCE_DIV
})

export const getAttendanceDivSuccess = (payload) =>({
    type: GET_ATTENDANCE_DIV_SUCCESS,
    payload
})

export const getAttendanceDivFailed = (payload) =>({
    type: GET_ATTENDANCE_DIV_FAILED,
    payload
})


export const getAttendStudList = (payload) =>({
    type: GET_STUDLIST,
    payload
})

export const getAttendStudListSuccess = (payload) =>({
    type: GET_STUDLIST_SUCCESS,
    payload
})

export const getAttendStudListFailed = (payload) =>({
    type: GET_STUDLIST_FAIL,
    payload
})

export const markAbsentStud = (payload) =>({
    type: MARK_ABSENT_STUD,
    payload
})


export const markAbsentStudSuccess = (payload) =>({
    type: MARK_ABSENT_STUD_SUCCESS,
    payload
})

export const markAbsentStudFailed = (payload) =>({
    type: MARK_ABSENT_STUD_FAILED,
    payload
})