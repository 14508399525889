import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { GET_SCHOOL_INFO } from "./constants";
import { getSchoolInfoApi } from "./api";
import { getSchoolInfoFailed, getSchoolInfoSuccess } from "./actions";

function* getSchoolInfoAsync() {
    try {
        const response = yield call(getSchoolInfoApi)
        if (response && Array.isArray(response?.data)) {
            yield put(getSchoolInfoSuccess(response?.data))
        } else {
            throw new Error("Error occured while getting school info");
        }

    } catch (error) {
        console.log("error", error)
        yield put(getSchoolInfoFailed(error.response?.message  || "Unable To Get School Info"))
    }
}

function* SchoolInfoSaga() {

    yield takeEvery(GET_SCHOOL_INFO, getSchoolInfoAsync)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default SchoolInfoSaga;