import React from 'react';

const Message = ({ text }) => {
    let finalText = '';
    const words = text.split(/\s+/); // Split by whitespace

    for (const word of words) {
        const moduleRegex = new RegExp(/https?:\/\/[^\s]+/g); // Match URLs starting with http or https
        const isExternalLink = moduleRegex.test(word);
        if (isExternalLink) {
            finalText += ` <a href="${word}" target="_blank" rel="noopener noreferrer">${word}</a>`;
        } else {
            finalText += ` ${word}`;
        }
    }

    return <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: finalText.trim() }} />;
};

export default Message;
