import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { GET_ALBUM, GET_ALBUM_IMAGES, UPLOAD_GALLERY_IMAGE } from "./constants";
import { getAlbumApi, getAlbumImagesApi, uploadGalleryImagesAPI } from "./api";
import { getAlbumFailed, getAlbumImagesFailed, getAlbumImagesSuccess, getAlbumSuccess, uploadGalleryImagesFailed, uploadGalleryImagesSuccess } from "./actions";

function* getAlbumAsync() {
    try {
        const response = yield call(getAlbumApi)
        if (response) {
            yield put(getAlbumSuccess(response?.data.records|| []))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getAlbumFailed(error?.response?.message || "Unable to get Album"))
    }
}


function* getAlbumImagesAsync(action) {
    try {
        const response = yield call(getAlbumImagesApi, action.payload)
        if (response) {
            yield put(getAlbumImagesSuccess(response?.data?.Images.reverse()|| []))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getAlbumImagesFailed(error?.response?.message || "Unable to get Album Images"))
    }
}

function* uploadGalleryImageAsync(action) {
    try {
        const response = yield call(uploadGalleryImagesAPI, action.payload)
        if (response) {
            yield put(uploadGalleryImagesSuccess(response?.data?.message|| "Uploaded Successfully!"))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(uploadGalleryImagesFailed(error?.response?.message || "Unable to upload Album Images"))
    }
}

function* GallarySaga() {

    yield takeEvery(GET_ALBUM, getAlbumAsync)
   yield takeLatest(GET_ALBUM_IMAGES, getAlbumImagesAsync)
   yield takeLatest(UPLOAD_GALLERY_IMAGE, uploadGalleryImageAsync)
}

export default GallarySaga;