import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"

import { createNotificationAPI, getClassDivAPI, getNotificationsAPI, getStudListAPI, getTecherNotificationAPI } from "./api";
import { CREATE_NOTIFICATION, GET_CLASSDIV, GET_NOTIFICATIONS, GET_STUD_LIST, GET_TEACHER_NOTIFICATION } from "./constants";
import { createNotificationFailed, createNotificationSuccess, getClassDivFailed, getClassDivSuccess, getNotificationFailed, getNotificationSuccess, getStudListFailed, getStudListSuccess, getTeacherNotificationFailed, getTeacherNotificationSuccess } from "./actions";


function* getNotificationsAsync() {
    try {
        const response = yield call(getNotificationsAPI)
        if (response) {
            yield put(getNotificationSuccess(response.data.data))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getNotificationFailed(error.response.data || "Unable to get Notifications, please try again"))
    }
}


function* getTeacherNotificationsAsync(action) {
    try {
        const response = yield call(getTecherNotificationAPI, action.payload)
        if (response) {
            if( action.payload == 2){
                yield put(getTeacherNotificationSuccess(response.data.inbox))
            }else{
                yield put(getTeacherNotificationSuccess(response.data.defaultNotify))
            }
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getTeacherNotificationFailed(error.response.data || "Unable to get Notifications, please try again"))
    }
}


function* getClassDivListAsync() {
    try {
        const response = yield call(getClassDivAPI)
        if (response) {
            yield put(getClassDivSuccess(response?.data?.data|| []))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getClassDivFailed(error.response.data || "Unable to get Class Division, please try again"))
    }
}


function* getstudListAsync() {
    try {
        const response = yield call(getStudListAPI)
        if (response) {
            yield put(getStudListSuccess(response?.data?.data|| []))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getStudListFailed(error.response.data || "Unable to get Class Division, please try again"))
    }
}


function* createNotificationAsync(action) {
    try {
        const response = yield call(createNotificationAPI, action.payload)
        if (response) {
            yield put(createNotificationSuccess("Notification Created Successfully"))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(createNotificationFailed(error.response.data || "Unable to get Class Division, please try again"))
    }
}

function* NotificationsSaga() {

    yield takeEvery(GET_NOTIFICATIONS, getNotificationsAsync)
    yield takeEvery(GET_TEACHER_NOTIFICATION, getTeacherNotificationsAsync)
    yield takeEvery(GET_CLASSDIV, getClassDivListAsync)
    yield takeEvery(GET_STUD_LIST, getstudListAsync)
    yield takeEvery(CREATE_NOTIFICATION, createNotificationAsync)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default NotificationsSaga;