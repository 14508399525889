import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getAlbumApi = async () => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/gallery/select_album`, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error While Getting Album:", error)
        throw error
    }
}

export const getAlbumImagesApi = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/gallery/view_images`, payload, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error While Getting Album Images: ", error)
        throw error
    }
}



export const uploadGalleryImagesAPI = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/gallery/upload_snap`, payload, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error While Getting Album Images: ", error)
        throw error
    }
}