import { GET_SCHOOL_INFO, GET_SCHOOL_INFO_FAILED, GET_SCHOOL_INFO_SUCCESS } from "./constants";

export const getSchoolInfo = () =>(
    {
        type: GET_SCHOOL_INFO
    }
)

export const getSchoolInfoSuccess = (payload) =>(
    {
        type: GET_SCHOOL_INFO_SUCCESS,
        payload
    }
)

export const getSchoolInfoFailed = (payload) =>(
    {
        type: GET_SCHOOL_INFO_FAILED,
        payload
    }
)