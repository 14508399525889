import {
  GET_SUBJECT_START,
  GET_SUBJECT_SUCCESS,
  GET_SUBJECT_FAILED,
  SUBMIT_REQUEST_START,
  SUBMIT_REQUEST_SUCCESS,
  SUBMIT_REQUEST_FAILED,
} from "./constant"

export const getSubjectListStart = payload => ({
  type: GET_SUBJECT_START,
  payload,
})

export const getSubjectListSuccess = payload => ({
  type: GET_SUBJECT_SUCCESS,
  payload,
})

export const getSubjectListFailed = payload => ({
  type: GET_SUBJECT_FAILED,
  payload,
})

export const SubmitAccessRequestStart = payload => ({
  type: SUBMIT_REQUEST_START,
  payload,
})

export const SubmitAccessRequestSuccess = payload => ({
  type: SUBMIT_REQUEST_SUCCESS,
  payload,
})

export const SubmitAccessRequestFailed = payload => ({
  type: SUBMIT_REQUEST_FAILED,
  payload,
})
