import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'reactstrap';
import { getAlbum } from 'store/actions';
import { Link } from 'react-router-dom';
import "../Dashboard/Dashboard.css"
import { useSelector } from 'react-redux';

const Gallery = () => {

    const { albumList } = useSelector(state => state.GallaryReducer)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAlbum())
    }, [])
    return (
        <div className='page-content'>
            <Row className="">
                {
                    albumList && Array.isArray(albumList) && albumList.map((data, index) => (
                        <Col md={2} xs={4} key={index}>
                            <Link
                                className="d-flex flex-column align-items-center justify-content-center mt-2"
                                to={"/gallery/images"}
                                state={{ id: data.id }}
                            >
                                <div
                                    className="iconContainer shadow-sm"
                                >
                                    <i className={`bx bx-folder dashIcons`}></i>
                                </div>
                                <span className="IconText"> {data.album_name} </span>
                            </Link>
                        </Col>
                    ))
                }

            </Row>
        </div>
    )
}

export default Gallery