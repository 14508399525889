import { GET_TIMETABLE, GET_TIMETABLE_DAYS, GET_TIMETABLE_DAYS_FAILED, GET_TIMETABLE_DAYS_SUCCESS, GET_TIMETABLE_FAILED, GET_TIMETABLE_SUCCESS, TIMETABLE_HIDE_COL, TIMETABLE_HIDE_COL_FAILED, TIMETABLE_HIDE_COL_SUCCESS } from "./Constants";

export const getTimeTableDays = (payload) =>({
    type: GET_TIMETABLE_DAYS,
    payload
})

export const getTimeTableDaysSuccess = (payload) =>({
    type: GET_TIMETABLE_DAYS_SUCCESS,
    payload
})

export const getTimeTableDaysFailed = (payload) =>({
    type: GET_TIMETABLE_DAYS_FAILED,
    payload
})


export const getTimeTable = (payload) =>({
    type: GET_TIMETABLE,
    payload
})

export const getTimeTableSuccess = (payload) =>({
    type: GET_TIMETABLE_SUCCESS,
    payload
})

export const getTimeTableFailed = (payload) =>({
    type: GET_TIMETABLE_FAILED,
    payload
})


export const timeTableHideCol = () =>({
    type: TIMETABLE_HIDE_COL
})


export const timeTableHideColSuccess = payload =>({
    type: TIMETABLE_HIDE_COL_SUCCESS,
    payload
})

export const timeTableHideColFailed = payload =>({
    type: TIMETABLE_HIDE_COL_FAILED,
    payload
})