import React, { useEffect, useState, useMemo } from "react"
import { Col, Row, Label, Badge, Card } from "reactstrap"
import axios from "axios"
import { getCurrentAcadid } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import Spinners from "components/Common/Spinner"
import { Link } from "react-router-dom"

const Home = () => {
  const token = JSON.parse(localStorage.getItem("Token"))

  const dispatch = useDispatch()
  const [div, setDiv] = useState([])
  const [loading, setLoading] = useState(false)
  const [div_id, setDiv_id] = useState(null)
  const [TestData, setTestData] = useState([])
  const { acadID } = useSelector(state => state.CommonReducer)

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [])

  //
  useEffect(() => {
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/examfillmarks/get-classdiv-list",
          data: {
            acad_id: acadID.acadid,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setDiv(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (acadID) {
      getClassDiv()
    }

    const getClassTestData = async () => {
      try {
        const response = await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/classtestfillmarks/get-test-data",
          data: {
            classdiv_id: div_id,
          },
          headers: {
            Authorization: token,
          },
        })

        console.log(response.data)
        setTestData(response.data.data)
      } catch (error) {
        console.error("Error fetching class test data:", error) // Add error handling
      }
    }

    if (div_id != null) {
      console.log("Student Data")
      getClassTestData()
    }
  }, [acadID, div_id])

  return (
    <div className="page-content">
      {/* <Breadcrumb breadcrumbItem="" title="Exam Fill Marks" /> */}
      <div className="container-fluid">
        {/* <p className="fw-bold text-muted mb-0 me-2">Select Class</p> */}
        <Col xl="4" className="mt-1">
          <select
            className="form-select rounded"
            aria-label="Default select example"
            onChange={e => setDiv_id(e.target.value)}
          >
            <option>Select Class</option>

            {div &&
              div.length > 0 &&
              div.map((item, index) => (
                <option key={item.id} value={item.id}>
                  {item.div_name}
                </option>
              ))}
          </select>
        </Col>

        {loading ? (
          <Spinners />
        ) : (
          <div>
            {TestData &&
              TestData.length > 0 &&
              TestData.map((item, index) => {
                return (
                  <div
                    // style={{
                    //   backgroundColor: "#fff",
                    //   border: "1px solid #ccc",
                    //   borderRadius: "4px",
                    //   marginTop: "5px",
                    //   padding: "5px",
                    //   position: "relative",
                    // }}
                    key={index}
                  >
                    <Card className="shadow-sm p-4 rounded mt-4">
                      <Row>
                        <div className="d-flex flex-row justify-content-between">
                          <span
                            style={{ fontWeight: 600 }}
                            className="mt-3 w-50"
                          >
                            Test Date
                          </span>
                          <span className="mt-3 w-50">{item.Test_Date}</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <span
                            style={{ fontWeight: 600 }}
                            className="mt-3 w-50"
                          >
                            Test Name
                          </span>
                          <span className="mt-3 w-50">{item.testName}</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <span
                            style={{ fontWeight: 600 }}
                            className="mt-3 w-50"
                          >
                            Out Of Marks
                          </span>
                          <span className="mt-3 w-50">{item.outOfMarks}</span>
                        </div>
                      </Row>
                      <Link
                        className="d-flex justify-content-end"
                        to={`/class-test/marks/${item.test_id}`}
                        state={{
                          classdiv_id: item.class_divid,
                          subject_id: item.subjectId,
                          acad_id: item.acad_id,
                          test_id: item.test_id,
                          test_name: item.testName,
                          test_date: item.Test_Date,
                          subject_name: item.subjectName,
                          outOfMarks: item.outOfMarks,
                          className: item.divisionName,
                        }}
                      >
                        <Badge className="bg-success">Exam Fill Marks</Badge>
                      </Link>
                    </Card>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
