import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getTimeTableDaysApi = async () => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        if (localStorage.getItem("group_id")) {
            if (localStorage.getItem("group_id") == 9) {
                return await axios.get(`${liveApiUrl}/api/teacher-timetable/timetabledays`, {
                    headers,
                })
            } else {
                return await axios.get(`${liveApiUrl}/api/parent-timetable/timetabledays`, {
                    headers,
                })
            }
        } else {
            throw new Error("Group Id is not found")
        }

    } catch (error) {
        console.error("[API] Error Gettting Time Table Days :", error)
        throw error
    }
}


export const getTimeTableApi = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

     //   const response = await axios.post(`${liveApiUrl}/api/parent-timetable/timetable`, payload, { headers })
        console.log(payload)
     if (localStorage.getItem("group_id")) {
            if (localStorage.getItem("group_id") == 9) {
                return await axios.post(`${liveApiUrl}/api/teacher-timetable/timetable`, payload, { headers })
            } else {
                return await axios.post(`${liveApiUrl}/api/parent-timetable/timetable`, payload, { headers })
            }
        } else {
            throw new Error("Group Id is not found")
        }
    } catch (error) {
        console.error("[API] Error Gettting Time Table:", error)
        throw error
    }
}


export const timeTableHideColApi = async () => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/parent-timetable/timetablehidecolumn`, { headers })
        return response
    } catch (error) {
        console.error("[API] Error Gettting Time Table:", error)
        throw error
    }
}
