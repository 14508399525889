import { CREATE_FEEDBACK, CREATE_FEEDBACK_FAILED, CREATE_FEEDBACK_SUCCESS, GET_FEEDBACK_LIST, GET_FEEDBACK_LIST_FAILED, GET_FEEDBACK_LIST_SUCCESS, GET_STAFF_LIST, GET_STAFF_LIST_FAILED, GET_STAFF_LIST_SUCCESS, OPEN_FEEDBACK_CHAT, OPEN_FEEDBACK_CHAT_FAILED, OPEN_FEEDBACK_CHAT_SUCCESS, SEND_FEEDBACK_MESSAGE, SEND_FEEDBACK_MESSAGE_FAILED, SEND_FEEDBACK_MESSAGE_SUCCESS } from "./constants"

export const getFeedbackList = () =>({
    type: GET_FEEDBACK_LIST
})
export const getFeedbackListSuccess = payload =>({
    type: GET_FEEDBACK_LIST_SUCCESS,
    payload
})
export const getFeedbackListFailed = payload =>({
    type: GET_FEEDBACK_LIST_FAILED,
    payload
})

export const createFeedback = (payload) =>({
    type: CREATE_FEEDBACK,
    payload
})

export const createFeedbackSuccess = (payload) =>({
    type: CREATE_FEEDBACK_SUCCESS,
    payload
})

export const createFeedbackFailed = (payload) =>({
    type: CREATE_FEEDBACK_FAILED,
    payload
})

export const sendFeedbackMsg = payload =>({
    type: SEND_FEEDBACK_MESSAGE,
    payload
})

export const sendFeedbackMsgSuccess = payload =>({
    type: SEND_FEEDBACK_MESSAGE_SUCCESS,
    payload
})

export const sendFeedbackMsgFailed = payload =>({
    type: SEND_FEEDBACK_MESSAGE_FAILED,
    payload
})

export const openFeedbackchats = payload =>({
    type: OPEN_FEEDBACK_CHAT,
    payload
})

export const openFeedbackchatsSuccess = payload =>({
    type: OPEN_FEEDBACK_CHAT_SUCCESS,
    payload
})

export const openFeedbackchatsFailed = payload =>({
    type: OPEN_FEEDBACK_CHAT_FAILED,
    payload
})

export const getStaffList = payload =>({
    type: GET_STAFF_LIST,
    payload
})

export const getStaffListSuccess = payload =>({
    type: GET_STAFF_LIST_SUCCESS,
    payload
})

export const getStaffListFailed = payload =>({
    type: GET_STAFF_LIST_FAILED,
    payload
})