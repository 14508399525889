import { CREATE_NOTIFICATION, CREATE_NOTIFICATION_FAILED, CREATE_NOTIFICATION_SUCCESS, GET_CLASSDIV, GET_CLASSDIV_FAILED, GET_CLASSDIV_SUCCESS, GET_NOTIFICATIONS, GET_NOTIFICATIONS_FAILED, GET_NOTIFICATIONS_SUCCESS, GET_STUD_LIST, GET_STUD_LIST_FAILED, GET_STUD_LIST_SUCCESS, GET_TEACHER_NOTIFICATION, GET_TEACHER_NOTIFICATION_FAILED, GET_TEACHER_NOTIFICATION_SUCCESS } from "./constants";

const initialState = {
    Loading: false,
    NotificationList: [],
    successMsg: "",
    errors: "",
    classDivList: [],
    studList: []
}

const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
        case GET_TEACHER_NOTIFICATION:
        case GET_CLASSDIV:
        case GET_STUD_LIST:
        case CREATE_NOTIFICATION:
            return {
                ...state,
                Loading: true,
                successMsg: "",
                errors: ""
            }
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                Loading: false,
                NotificationList: action.payload
            }
        case GET_TEACHER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                Loading: false,
                NotificationList: action.payload
            }
        case GET_CLASSDIV_SUCCESS:
            return {
                ...state,
                Loading: false,
                classDivList: action.payload
            }
        case GET_STUD_LIST_SUCCESS:
            return {
                ...state,
                Loading: false,
                studList: action.payload
            }
        case CREATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                Loading: false,
                successMsg: action.payload
            }

        case GET_NOTIFICATIONS_FAILED:
        case GET_TEACHER_NOTIFICATION_FAILED:
        case GET_CLASSDIV_FAILED:
        case GET_STUD_LIST_FAILED:
        case CREATE_NOTIFICATION_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload
            }

        default:
            return state
    }
}

export default NotificationReducer;