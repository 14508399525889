import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAlbumImages, uploadGalleryImages } from 'store/actions';
import sampleImage from "./../../assets/images/users/avatar-1.jpg"
import { Col, Input, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import Spinners from 'components/Common/Spinner';
import { toast } from 'react-toastify';
const ImageGallery = () => {

    const dispatch = useDispatch();

    const location = useLocation();
    const { id } = location.state;

    const { imageList, Loading, errors, successMsg } = useSelector(state => state.GallaryReducer);

    const FileUploadstyle = {
        fileUpload: {
            display: 'none',
        },
        fileUploadLabel: {
            display: 'inline-block',
            padding: '5px 3px',
            cursor: 'pointer',
            fontSize: '15px',
        },
    };

    const handleFileUpload = (file) => {
        const fileList = file.target.files;
        const formData = new FormData();
        formData.append("files", fileList[0]);
        formData.append("album_id", id);

        dispatch(uploadGalleryImages(formData))

    }

    useEffect(() => {
        if (errors && errors !== null) {
            toast.error(errors)
        }
        if (successMsg && successMsg !== null) {
            toast.success(successMsg)
        }

    }, [errors, successMsg])

    useEffect(() => {
        dispatch(getAlbumImages({ id }))
    }, [dispatch])

    return (
        <div className='page-content'>

            {
                Loading ?
                    <Spinners /> :

                    <Row>
                        <Col xs={4} md={2} className='d-flex align-items-center justify-content-center'>
                            <div>

                                <Input
                                    type="file"
                                    id="file-upload"
                                    style={FileUploadstyle.fileUpload}
                                    onChange={(e) => handleFileUpload(e)}
                                    accept="image/*"
                                />
                                <Label
                                    for="file-upload"
                                    // style={FileUploadstyle.fileUploadLabel}
                                    title="file attach"
                                    className='text-center'
                                >
                                    <i className='bx bx-plus border border-2 border-secondary rounded-3' style={{ fontSize: "40px" }}></i>
                                    <p className='m-0 p-0 text-center '>Upload</p>
                                </Label>
                            </div>

                        </Col>
                        {
                            imageList && Array.isArray(imageList) && imageList.map((data, index) => (
                                <Col xs={4} md={2} className='p-2' key={index}>
                                    <img src={data.snap} className='border border-secondary rounded-2' alt='galleryImage' width={"90px"} height={"90px"}/>
                                </Col>
                            ))
                        }



                    </Row>
            }
        </div>
    )
}

export default ImageGallery