import { CREATE_FEEDBACK, CREATE_FEEDBACK_FAILED, CREATE_FEEDBACK_SUCCESS, GET_FEEDBACK_LIST, GET_FEEDBACK_LIST_FAILED, GET_FEEDBACK_LIST_SUCCESS, GET_STAFF_LIST, GET_STAFF_LIST_FAILED, GET_STAFF_LIST_SUCCESS, OPEN_FEEDBACK_CHAT, OPEN_FEEDBACK_CHAT_FAILED, OPEN_FEEDBACK_CHAT_SUCCESS, SEND_FEEDBACK_MESSAGE, SEND_FEEDBACK_MESSAGE_FAILED, SEND_FEEDBACK_MESSAGE_SUCCESS } from "./constants";

const initialState = {
    Loading: false,
    successMsg: "",
    errors: "",
    feedbacklist: [],
    messageList: [],
    staffList: [],
    sendMessageStatus:0
}

export const FeedBackReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FEEDBACK_LIST:
        case GET_STAFF_LIST:
        case CREATE_FEEDBACK:
        case SEND_FEEDBACK_MESSAGE:
        case OPEN_FEEDBACK_CHAT:
            return {
                ...state,
                Loading: true,
                sendMessageStatus: 0,
                successMsg: "",
                errors: "",
            }
        case GET_FEEDBACK_LIST_FAILED:
        case GET_STAFF_LIST_FAILED:
        case CREATE_FEEDBACK_FAILED:
        case SEND_FEEDBACK_MESSAGE_FAILED:
        case OPEN_FEEDBACK_CHAT_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload,
            }
        case GET_FEEDBACK_LIST_SUCCESS:
            return {
                ...state,
                Loading: false,
                feedbacklist: action.payload
            }
        case CREATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                Loading: false,
                successMsg: action.payload
            }
        case SEND_FEEDBACK_MESSAGE_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                Loading: false,
                sendMessageStatus: 1,
                messageList: [...state.messageList, {message: action.payload, direction: 'out'}]
            }
        case OPEN_FEEDBACK_CHAT_SUCCESS:
            return {
                ...state,
                Loading: false,
                messageList: action.payload.reverse()
            }
        case GET_STAFF_LIST_SUCCESS:
            return {
                ...state,
                Loading: false,
                staffList: action.payload
            }

        default:
            return state;
    }
}

export default FeedBackReducer