import html2canvas from "html2canvas"
import moment from "moment"
import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { Button, Spinner, Table } from "reactstrap"
import { printPaidFeesDeatails } from "store/actions"
import { saveAs } from "file-saver"
import jsPDF from "jspdf" // Make sure jsPDF is installed
const PrintRecipt = () => {
  const captureRef = useRef()

  const location = useLocation()
  const dispatch = useDispatch()
  const { schoolInfo, Loading } = useSelector(state => state.SchoolInfoReducer)
  const { printRecipt, Loading: feesLoading } = useSelector(
    state => state.FeesReducer
  )
  const { printId, acadInfo } = location.state

  const calTotalFees = array => {
    // console.log(array)
    let totalFees = 0
    if (array && Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        totalFees += array[i].amount
      }
    }

    return totalFees
  }

  useEffect(() => {
    dispatch(printPaidFeesDeatails({ printId: printId }))
  }, [dispatch])

  const downloadPdf = () => {
    // Set html2canvas options to increase the resolution
    html2canvas(captureRef.current, {
      scale: 3, // Increase scale for higher resolution (default is 1)
      useCORS: true, // Enable CORS for cross-origin images (if needed)
    }).then(canvas => {
      // Get the image data from the canvas as PNG
      const imageData = canvas.toDataURL("image/png");
  
      // Create a new jsPDF instance
      const pdf = new jsPDF();
  
      // Add the image to the PDF (adjust size to fit the page)
      // You can adjust the position and size here as needed
      const imgWidth = 180;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imageData, "PNG", 10, 10, imgWidth, imgHeight);
  
      // Save the PDF with a better quality
      pdf.save("feesReceipt.pdf");
    });
  };
  
  return (
    <div className="page-content h-100 px-1 bg-white">
      <div ref={captureRef}>
        <div className="LogoContainer">
          <img
            src={schoolInfo.school_logo}
            alt={schoolInfo.name}
            className="schoolLogo"
          />
          <span
            className="d-block text-dark"
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: schoolInfo.name }}
          />
        </div>
        <div className="my-3">
          <table className="w-100 ml-14">
            <tr>
              <td className="font-medium">Student Name: </td>
              <td>
                {printRecipt &&
                  Array.isArray(printRecipt?.data) &&
                  printRecipt?.data[0]?.name}
              </td>
            </tr>
            <tr>
              <td>Acadmic Year: </td>
              <td>{acadInfo?.academics && acadInfo?.academics}</td>
            </tr>
            <tr>
              <td>Recipt No: </td>
              <td>
                {printRecipt &&
                  Array.isArray(printRecipt?.data) &&
                  printRecipt?.data[0]?.rcp_no}
              </td>
            </tr>
          </table>
        </div>
        {feesLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="">
              <Table className="">
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Category</th>
                    <th>date</th>
                    <th>amount</th>
                  </tr>
                </thead>
                <tbody>
                  {printRecipt &&
                    printRecipt.data?.map((data, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td>{data.category}</td>
                        <td>{moment(data.date).format("DD-MM-YYYY")}</td>
                        <td>{data.amount}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={2}>Total</th>
                    <th>{calTotalFees(printRecipt?.data)}</th>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        )}
      </div>
      <div className="text-center no-printme">
        <Button onClick={downloadPdf}>Download</Button>
      </div>
    </div>
  )
}

export default PrintRecipt
