const { VIEW_PAIDFEES_DETAILS, VIEW_PAIDFEES_DETAILS_SUCCESS, VIEW_PAIDFEES_DETAILS_FAILED, PRINT_PAIDFEES_DETAILS, PRINT_PAIDFEES_DETAILS_FAILED, PRINT_PAIDFEES_DETAILS_SUCCESS } = require("./constants")

const inintialState = {
    errors: "",
    successMsg: "",
    Loading: "",
    paidFees: {},
    printRecipt: []
}

const FeesReducer = (state = inintialState, action) => {
    switch (action.type) {
        case VIEW_PAIDFEES_DETAILS:
        case PRINT_PAIDFEES_DETAILS:
            return {
                ...state,
                Loading: true,
            }
        case VIEW_PAIDFEES_DETAILS_SUCCESS:
            return {
                ...state,
                Loading: false,
                paidFees: action.payload
            }
        case PRINT_PAIDFEES_DETAILS_SUCCESS:
            return {
                ...state,
                Loading: false,
                printRecipt: action.payload
            }
        case VIEW_PAIDFEES_DETAILS_FAILED:
        case PRINT_PAIDFEES_DETAILS_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload
            }

        default: return state
    }
}

export default FeesReducer;