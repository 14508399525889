import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const HandleRoutes = () => {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get('url');

    useEffect(() => {
        console.log("URL parameter:", url); 

        if (!url) {
            console.log("url not found");
            return;
        }

        const moduleRegex = /http|https|.com|.in|.prisms.in/g;
        const isExternalLink = moduleRegex.test(url);
        console.log("Is external link:", isExternalLink);

        if (isExternalLink) {

            navigate("/external-link", {
                state: {
                    webLink: url
                }
            });
        } else {
            console.log("Navigating to internal URL:", url);
            navigate(url);
        }
    }, [url, navigate]);

    return null;
};

export default HandleRoutes;
