export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED"

export const GET_TEACHER_NOTIFICATION = "GET_TEACHER_NOTIFICATION"
export const GET_TEACHER_NOTIFICATION_SUCCESS = "GET_TEACHER_NOTIFICATION_SUCCESS"
export const GET_TEACHER_NOTIFICATION_FAILED = "GET_TEACHER_NOTIFICATION_FAILED"


export const GET_CLASSDIV = "GET_CLASSDIV"
export const GET_CLASSDIV_SUCCESS = "GET_CLASSDIV_SUCCESS"
export const GET_CLASSDIV_FAILED = "GET_CLASSDIV_FAILED"


export const GET_STUD_LIST = "GET_STUD_LIST"
export const GET_STUD_LIST_SUCCESS = "GET_STUD_LIST_SUCCESS"
export const GET_STUD_LIST_FAILED = "GET_STUD_LIST_FAILED"

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION"
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS"
export const CREATE_NOTIFICATION_FAILED = "CREATE_NOTIFICATION_FAILED"