import React from "react"
import { Modal, ModalBody } from "reactstrap"

const DeleteModal = ({ show, onClickYes, onCloseClick, division }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <p className="text-muted font-size-16 mb-4">
            Are you sure class teacher of {division} division ?
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onClickYes}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              No
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default DeleteModal
