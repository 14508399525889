import React, { useEffect } from 'react'
import "./PaidFeesStyle.css"
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { getCurrentAcadid, printPaidFeesDeatails, viewPaidFeesDetails } from 'store/actions'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
const PaidFeesDetails = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { paidFees, Loading, errors } = useSelector(state => state.FeesReducer);
  const { acadID } = useSelector(state => state.CommonReducer);

  const calTotalFees = (array) => {
    let totalFees = 0;
    for (let i = 0; i < array.length; i++) {
      totalFees += array[i]
    }
    return totalFees
  }

  const handleClickPrint = (reciptno) => {
    navigate("/printpaidfeesdetails",{state:{printId: "userId"+localStorage.getItem("user_id")+","+"acadId"+acadID.acadid+","+"receipt"+reciptno, acadInfo: acadID}})
  }

  useEffect(() => {
    if (acadID && acadID.acadid) {
      dispatch(viewPaidFeesDetails({
        acadid: acadID.acadid
      }))
    }

  }, [acadID])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(getCurrentAcadid({
        sid: localStorage.getItem("sid")
      }))
    }

  }, [])


  return (
    <div className='page-content m-0 px-0'>

      <div className='upper-div'>
        <p className='fw-bold mt-3 fs-5'>{paidFees?.package_name && paidFees?.package_name[0]?.pkg_name}</p>
        <div className='info-section'>
          <div className='balance-info'>
            <div className='left-section'>
              <p>Total Paid</p>
              <h2>{paidFees?.paidFees1Arr && calTotalFees(paidFees?.paidFees1Arr)}</h2>
            </div>

          </div>
          {/* <div className='actions'>
            <Button>Print Recipt</Button>
            <Button>Transport</Button>

          </div>  */}
        </div>

      </div>

      <div className='bottom-div'>

        {
          paidFees?.paid_fees && paidFees.paid_fees.map((data, index) => (
            <div className='record-card shadow-sm rounded-3' key={index}>
              <div>
                <span>Recipt No: {data.rcp_no}</span>
                <p>
                  <b>{data.category}</b>
                </p>
                <span>{moment(data.date).format("DD/MM/YYYY")}</span>
              </div>

              <div className='h-100 d-flex justify-content-center align-items-center'>

                <div className='d-flex flex-column justify-content-between align-items-end me-2'>

                  <p className='m-0'>
                    <b>{data.amount}</b>
                  </p>
                  <span>{data.pay_mode}</span>
                </div>

                <i className='bx bx-printer fs-4' onClick={()=>handleClickPrint(data.rcp_no)}></i>

              </div>
            </div>
          ))
        }


      </div>
    </div>
  )
}

export default PaidFeesDetails