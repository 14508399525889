import { CREATE_NOTIFICATION, CREATE_NOTIFICATION_FAILED, CREATE_NOTIFICATION_SUCCESS, GET_CLASSDIV, GET_CLASSDIV_FAILED, GET_CLASSDIV_SUCCESS, GET_NOTIFICATIONS, GET_NOTIFICATIONS_FAILED, GET_NOTIFICATIONS_SUCCESS, GET_STUD_LIST, GET_STUD_LIST_FAILED, GET_STUD_LIST_SUCCESS, GET_TEACHER_NOTIFICATION, GET_TEACHER_NOTIFICATION_FAILED, GET_TEACHER_NOTIFICATION_SUCCESS } from "./constants";

export const getNotification = payload =>({
    type: GET_NOTIFICATIONS,
    payload
})

export const getNotificationSuccess = payload =>({
    type: GET_NOTIFICATIONS_SUCCESS,
    payload
})

export const getNotificationFailed = payload =>({
    type: GET_NOTIFICATIONS_FAILED,
    payload
})


export const getTeacherNotification = payload =>({
    type: GET_TEACHER_NOTIFICATION,
    payload
})

export const getTeacherNotificationSuccess = payload =>({
    type: GET_TEACHER_NOTIFICATION_SUCCESS,
    payload
})

export const getTeacherNotificationFailed = payload =>({
    type: GET_TEACHER_NOTIFICATION_FAILED,
    payload
})


export const getClassDiv = () =>({
    type: GET_CLASSDIV
})

export const getClassDivSuccess = (payload) =>({
    type: GET_CLASSDIV_SUCCESS,
    payload
})

export const getClassDivFailed = (payload) =>({
    type: GET_CLASSDIV_FAILED,
    payload
})

export const getStudList = () =>({
    type: GET_STUD_LIST
})

export const getStudListSuccess = payload =>({
    type: GET_STUD_LIST_SUCCESS,
    payload
})

export const getStudListFailed = payload =>({
    type: GET_STUD_LIST_FAILED,
    payload
})

export const createNotification = payload =>({
    type: CREATE_NOTIFICATION,
    payload
})

export const createNotificationSuccess = payload =>({
    type: CREATE_NOTIFICATION_SUCCESS,
    payload
})

export const createNotificationFailed = payload =>({
    type: CREATE_NOTIFICATION_FAILED,
    payload
})