import { GET_SCHOOL_INFO, GET_SCHOOL_INFO_FAILED, GET_SCHOOL_INFO_SUCCESS } from "./constants";

const initialState = {
    Loading: false,
    errors: "",
    successMsg: "",
    schoolInfo: {

    }
}

const SchoolInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHOOL_INFO:
            return {
                ...state,
                Loading: true,
                schoolInfo: {

                }
            }

        case GET_SCHOOL_INFO_SUCCESS:
            return {
                ...state,
                Loading: false,
                schoolInfo: action.payload[0]
            }
        case GET_SCHOOL_INFO_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload
            }

        default:
            return state;
    }
}

export default SchoolInfoReducer;