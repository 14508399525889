import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Spinners from './Spinner';

const MediaView = () => {
    const location = useLocation()
    const locationState = location.state || {};
    const { fileurl } = locationState;

    const [Loading, setLoading] = useState(true)

    const handleIframeLoad = () => {
        // Handle the iframe load completion (e.g., hide loading spinner)
        //IF page is get loaded  this function is called
        setLoading(false)
    };

    const filePreview = (file) => {
        const ext = file.split('.').pop().toLowerCase();
        switch (ext) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return (
                    <img src={file} alt='media' width={"100%"} onLoad={handleIframeLoad} className='rounded-2' />
                );
            case "doc":
            case "docx":
            case "xls":
            case "xlsx":
            case "ppt":
            case "pptx":
            case "pdf":
                return (
                    <iframe
                        src={`https://docs.google.com/viewer?url=${file}&embedded=true`}
                        width={"100%"}
                        height={"500px"}
                        title='document'
                        onLoad={handleIframeLoad}>
                    </iframe>
                );
            case "txt":
                return (
                    <iframe
                        src={`data:text/plain;charset=utf-8,${encodeURIComponent(file)}`}
                        width={"100%"}
                        height={"500px"}
                        title='text file'
                        onLoad={handleIframeLoad}>
                    </iframe>
                );
            case "zip":
            case "rar":
                return (
                    <h6>Archive File: {file}</h6>
                );
            case "mp3":
            case "3gp":
                return (
                    <audio controls>
                        <source src={file} type={`audio/${ext}`} onLoad={handleIframeLoad} />
                        Your browser does not support the audio element.
                    </audio>
                );
            default:
                return (
                    <h6>No Supported File, please contact the admin.</h6>
                );
        }
    };

    return (
        <div className='page-content'>
            <div style={{ display: !Loading ? 'none' : 'block' }}>
                <Spinners />
            </div>
            <div>

                <a href={fileurl} download="Top School Attachment" className='btn btn-primary position-absolute me-2 top-0 end-0' style={{ marginTop: "90px" }}>Download</a>
            </div>
            <div className='d-flex justify-content-center' style={{ marginTop: "50px" }}>
              { fileurl? <div style={{ display: Loading ? 'none' : 'block', width: "100%", maxWidth: "500px" }}>
                    {filePreview(fileurl)}
                </div>
                :"No File to preview"}
            </div>

        </div>)
}

export default MediaView