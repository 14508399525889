import { GET_ALBUM, GET_ALBUM_FAILED, GET_ALBUM_IMAGES, GET_ALBUM_IMAGES_FAILED, GET_ALBUM_IMAGES_SUCCESS, GET_ALBUM_SUCCESS, UPLOAD_GALLERY_IMAGE, UPLOAD_GALLERY_IMAGE_FAILED, UPLOAD_GALLERY_IMAGE_SUCCESS } from "./constants";

export const getAlbum = () =>({
    type: GET_ALBUM
})

export const getAlbumSuccess = payload =>({
    type: GET_ALBUM_SUCCESS,
    payload
})

export const getAlbumFailed = payload =>({
    type: GET_ALBUM_FAILED,
    payload
})


export const getAlbumImages = (payload) =>({
    type: GET_ALBUM_IMAGES,
    payload
})

export const getAlbumImagesSuccess = payload =>({
    type: GET_ALBUM_IMAGES_SUCCESS,
    payload
})

export const getAlbumImagesFailed = payload =>({
    type: GET_ALBUM_IMAGES_FAILED,
    payload
})

export const uploadGalleryImages = payload =>({
    type: UPLOAD_GALLERY_IMAGE,
    payload
})

export const uploadGalleryImagesSuccess = payload =>({
    type: UPLOAD_GALLERY_IMAGE_SUCCESS,
    payload
})

export const uploadGalleryImagesFailed = payload =>({
    type: UPLOAD_GALLERY_IMAGE_FAILED,
    payload
})