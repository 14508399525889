import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, GET_PROFILE_DETAIL, GET_PROFILE_DETAIL_SUCCESS, GET_PROFILE_DETAIL_FAILED } from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}


export const getProfileDetail = () =>({
  type: GET_PROFILE_DETAIL
})


export const getProfileDetailSuccess = (payload) =>({
  type: GET_PROFILE_DETAIL_SUCCESS,
  payload
})

export const getProfileDetailFailed = (payload) =>({
  type: GET_PROFILE_DETAIL_FAILED,
  payload
})