import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";

const Spinners = () => {
    const loadingMessages = [
        "Hang tight, we're fetching your data!",
        "Just a moment, magic is happening...",
        "Loading... Grab a coffee while you wait!",
        "Good things take time, almost there!",
        "Hold on, we're making things awesome for you!",
        "Patience is a virtue, loading in progress...",
        "Sit back and relax, we're on it!",
        "Loading... Your patience is appreciated!",
        "Almost there, just a few more seconds!",
        "Loading... Thanks for waiting!"
    ];

    const getRandomMessage = () => {
        const randomIndex = Math.floor(Math.random() * loadingMessages.length);
        return loadingMessages[randomIndex];
    };

    const [loadingMessage, setLoadingMessage] = useState(getRandomMessage());

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setLoadingMessage(getRandomMessage());
    //     }, 5000); // Change message every 3 seconds

    //     return () => clearInterval(interval); // Cleanup interval on component unmount
    // }, []);

    return (
        <React.Fragment>
            <div className='w-100 d-flex flex-column align-items-center justify-content-center' style={{height:"70vh"}}>
                <Spinner color="primary"  />
                <p className="loading-text mt-2 text-secondary">{loadingMessage}</p>
            </div>
          
        </React.Fragment>
    );
}

export default Spinners;
