import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"
import React, { useState, useEffect } from "react"
import { Link, NavLink } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import "./BottomNav.css"
import { useSelector } from "react-redux"
const Footer = () => {

  const pathname = window.location.pathname;

  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const { bottomNavIcons } = useSelector(state => state.DashReducer)

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const initialHeight = window.screen.height;

      // If the difference is significant, assume the keyboard is visible
      const keyboardThreshold = 100; // Adjust as needed
      setKeyboardVisible(initialHeight - windowHeight > keyboardThreshold);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      {/* <footer className="footer">*/}
      {/* <footer className="bg-white shadow position-fixed fixed-bottom w-100 border border-dark" style={{height:"60px", zIndex:0}}>
        <Container fluid={true} className="d-flex align-items-center justify-content-around h-100 w-100">
         <div><Link to={"/dashboard"}><i className="bx bx-home-circle text-dark" style={{fontSize:"2.2rem"}}></i></Link></div>
         <div><Link to={"/notifications"}><i className="bx bx-bell text-dark" style={{fontSize:"2.2rem"}}></i></Link></div>
         <div><ProfileMenu style={{fontSize:"30px"}}/></div>
        </Container>
      </footer> */}

      {!isKeyboardVisible ?

        <footer className="bottomNavbar no-printme">
          {/* {
            bottomNavIcons && bottomNavIcons.length > 0 &&
            bottomNavIcons.map((icons, index) => (
              <NavLink
                to={icons.url}
                key={index}
                className={"footerIconContainer"}
              >
                <i className={`${icons.icon} footerIcon ${pathname === icons.url ? '' : 'text-dark'}`}></i>
                <span className="footerIconText">{icons.NAME}</span>
              </NavLink>
            ))
          } */}

          {/* <NavLink to={"/notifications"}>
            <i className={`bx bx-bell footerIcon ${pathname === "/notifications" ? 'text-primary' : 'text-dark'}`}></i>
            <span className="text-dark">Notifications</span>
          </NavLink> */}

          <nav className="mobile-bottom-nav">
            {
              bottomNavIcons && bottomNavIcons.length > 0 &&
              bottomNavIcons.map((icons, index) => (
                <NavLink
                  to={icons.url}
                  key={index}
                  className={`mobile-bottom-nav__item ${pathname === icons.url ? 'text-primary' : 'text-dark'}`}
                >
                  
                    <div className="mobile-bottom-nav__item-content">
                      <i className={`${icons.icon} mobile-bottom-nav_icon`} />
                      {icons.NAME}
                    </div>
                  
                </NavLink>
              ))
            }
          </nav>

        </footer> : null}
    </React.Fragment>
  )
}

export default Footer
