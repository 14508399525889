
import { GET_ATTENDANCE_DIV, GET_ATTENDANCE_DIV_FAILED, GET_ATTENDANCE_DIV_SUCCESS, GET_STUDLIST, GET_STUDLIST_FAIL, GET_STUDLIST_SUCCESS, MARK_ABSENT_STUD, MARK_ABSENT_STUD_FAILED, MARK_ABSENT_STUD_SUCCESS } from "./constants";

const initialState = {
    Loading: false,
    successMsg: "",
    errors: "",
    divisions: [],
    studList: []
}

const AttendanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ATTENDANCE_DIV:
        case GET_STUDLIST:
        case MARK_ABSENT_STUD:
            return {
                ...state,
                Loading: true,
            }
        case GET_ATTENDANCE_DIV_SUCCESS:
            return {
                ...state,
                Loading: false,
                divisions: action.payload?.data ? action.payload?.data : []
            }
        case GET_STUDLIST_SUCCESS:
            return {
                ...state,
                Loading: false,
                studList: action.payload.data
            }
        case MARK_ABSENT_STUD_SUCCESS:
            return {
                ...state,
                Loading: false,
                successMsg: action.payload
            }
        case GET_ATTENDANCE_DIV_FAILED:
        case GET_STUDLIST_FAIL:
        case MARK_ABSENT_STUD_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload
            }

        default:
            return state
    }
}

export default AttendanceReducer;