import { GET_CURRENT_ACADID, GET_CURRENT_ACADID_FAILED, GET_CURRENT_ACADID_SUCCESS } from "./Constants";

const initialState = {
    Loading: false,
    successMsg: "",
    errors: "",
    acadID: []
}

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_ACADID:
            return {
                ...state,
                Loading: true,
            }
        case GET_CURRENT_ACADID_SUCCESS:
            return {
                ...state,
                Loading: false,
                acadID: action.payload
            }
        case GET_CURRENT_ACADID_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload
            }

        default:
            return state
    }
}
export default CommonReducer;