import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { GET_CURRENT_ACADID } from "./Constants";
import { getCurrentAcadIdAPI } from "./Api";
import { getCurrentAcadidFailed, getCurrentAcadidSuccess } from "./Actions";

function* getCurrentAcadId(action) {
    try {
        const response = yield call(getCurrentAcadIdAPI, action.payload)
        if (response) {
            yield put(getCurrentAcadidSuccess(Array.isArray(response.data) ? response.data[0]:[]))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(getCurrentAcadidFailed(error.response.data || "Unable to get Bottom Nav Icons Icons"))
    }
}

function* CommonSaga() {

    yield takeEvery(GET_CURRENT_ACADID, getCurrentAcadId)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default CommonSaga;