import { GET_TIMETABLE, GET_TIMETABLE_DAYS, GET_TIMETABLE_DAYS_FAILED, GET_TIMETABLE_DAYS_SUCCESS, GET_TIMETABLE_FAILED, GET_TIMETABLE_SUCCESS, TIMETABLE_HIDE_COL, TIMETABLE_HIDE_COL_FAILED, TIMETABLE_HIDE_COL_SUCCESS } from "./Constants";

const initialState = {
    errors: "",
    successMsg: "",
    Loading: false,
    timeTableDays: [],
    timeTable: [],
    HideCol: { "subjecthide": "0", "teacherhide": "0", "classhide": "0" }
}

const TimeTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIMETABLE_DAYS:
        case GET_TIMETABLE:
        case TIMETABLE_HIDE_COL:
            return {
                ...state,
                Loading: true
            }

        case GET_TIMETABLE_DAYS_FAILED:
        case GET_TIMETABLE_FAILED:
        case TIMETABLE_HIDE_COL_FAILED:
            return {
                ...state,
                Loading: false,
                errors: action.payload
            }
        case TIMETABLE_HIDE_COL_SUCCESS:
            return {
                ...state,
                Loading: false,
                timeTableHideCol: action.payload[0]
            }

        case GET_TIMETABLE_DAYS_SUCCESS:
            return {
                ...state,
                Loading: false,
                timeTableDays: action.payload
            }
        case GET_TIMETABLE_SUCCESS:
            return {
                ...state,
                Loading: false,
                timeTable: action.payload
            }

        default: return state;
    }
}

export default TimeTableReducer;