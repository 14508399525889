import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { PRINT_PAIDFEES_DETAILS, VIEW_PAIDFEES_DETAILS } from "./constants";
import { printPaidFeesDetailAPI, viewPaidFeesDetailAPI } from "./api";
import { printPaidFeesDeatailsFailed, printPaidFeesDeatailsSuccess, viewPaidFeesDetailsFailed, viewPaidFeesDetailsSuccess } from "./action";

function* viewPaidFeesDetailsAsync(action) {
    try {
        const response = yield call(viewPaidFeesDetailAPI, action.payload)
        if (response) {
            yield put(viewPaidFeesDetailsSuccess(response?.data|| {}))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(viewPaidFeesDetailsFailed(error?.response?.message || "Unable to view paid fees details"))
    }
}


function* printPaidFeesDetailsAsync(action) {
    try {
        const response = yield call(printPaidFeesDetailAPI, action.payload)
        if (response) {
            yield put(printPaidFeesDeatailsSuccess(response?.data|| {}))
        } else {
            throw response;
        }

    } catch (error) {
        console.log("error", error)
        yield put(printPaidFeesDeatailsFailed(error?.response?.message || "Unable to view paid fees details"))
    }
}

function* FeesSaga() {

    yield takeEvery(VIEW_PAIDFEES_DETAILS, viewPaidFeesDetailsAsync)
    yield takeEvery(PRINT_PAIDFEES_DETAILS, printPaidFeesDetailsAsync)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default FeesSaga;